<template>
	<div class="card">
		<div class="card-header bg-white border-bottom-str">
			<h6 class="card-title font-weight-semibold">Tambah Kajian Nyeri</h6>
		</div>
		<validation-observer ref="VForm">
			<b-form @submit.prevent="doSubmit">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6 col-lg-4">
							<div class="form-group">
								<label for="kenyamananNyeri">Tanggal <span class="text-danger">*</span></label>
								<div class="input-group mb-3">
									<datepicker input-class="form-control transparent h-38" placeholder="Tanggal" class="my-datepicker"
										calendar-class="my-datepicker_calendar" v-model="rowData.arankpkn_date">
									</datepicker>

									<vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38"
										v-model="rowData.arankpkn_jam">
									</vue-timepicker>
									<div class="input-group-append calendar-group">
										<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
									</div>
								</div>
								<VValidate :name="'Tanggal'" v-model="rowData.arankpkn_date" :rules="{required : 1}"
									class="d-block" />
								<VValidate :name="'Jam'" v-model="rowData.arankpkn_jam" :rules="{required: 1}" />
							</div>
						</div>
					</div>
					<div class="row g-2 mt-2">
						<div class="col-12" v-if="isParent.row.ap_usia > 6">
							<div class="card">
								<div class="card-header bg-info">
									<h6 class="card-title font-weight-semibold">Numeric Pain Rating Scale</h6>
								</div>
								<div class="card-body">
									<div class="row align-items-center">
										<div class="col-md-auto">
											<label for="perPain">Skala Nyeri<strong class="text-danger">*</strong></label>
											<div>
												<div v-for="(v,k) in Config.mr.skalaNyeri" :key="k" :class="`pain-scale-radio ${v.class}`">
													<input type="radio" v-model="rowData.arankpknd_skala_nyeri_value" name="perPainScale"
														:id="`perPainScale${v.text}`" :value="v.value" class="psr-input">
													<label :for="`perPainScale${v.text}`" class="psr-label">
														<span class="psr-label-content">{{v.text}}</span>
														<div class="psr-check-container"></div>
													</label>
												</div>
											</div>
										</div>
										<div class="col-md">
											<div class="pain-scale no-pain" v-if="rowData.arankpknd_skala_nyeri_value == 0">
												<img :src="assetLocal('global_assets/images/pain-scale/1-no-pain.png')" alt="Pain Scale"
													width="80" height="80">
												<strong>Tidak Ada Nyeri</strong>
											</div>
											<div class="pain-scale mild-pain"
												v-if="rowData.arankpknd_skala_nyeri_value >= 1 && rowData.arankpknd_skala_nyeri_value <= 3">
												<img :src="assetLocal('global_assets/images/pain-scale/2-mild-pain.png')" alt="Pain Scale"
													width="80" height="80">
												<strong>Sedikit Nyeri</strong>
											</div>
											<div class="pain-scale moderate-pain"
												v-if="rowData.arankpknd_skala_nyeri_value >= 4 && rowData.arankpknd_skala_nyeri_value <= 5">
												<img :src="assetLocal('global_assets/images/pain-scale/3-moderate-pain.png')" alt="Pain Scale"
													width="80" height="80">
												<strong>Agak Mengganggu</strong>
											</div>
											<div class="pain-scale severe-pain"
												v-if="rowData.arankpknd_skala_nyeri_value >= 6 && rowData.arankpknd_skala_nyeri_value <= 7">
												<img :src="assetLocal('global_assets/images/pain-scale/4-severe-pain.png')" alt="Pain Scale"
													width="80" height="80">
												<strong>Mengganggu Aktivitas</strong>
											</div>
											<div class="pain-scale worst-pain"
												v-if="rowData.arankpknd_skala_nyeri_value >= 8 && rowData.arankpknd_skala_nyeri_value <= 9">
												<img :src="assetLocal('global_assets/images/pain-scale/5-very-severe.png')" alt="Pain Scale"
													width="80" height="80">
												<strong>Sangat Mengganggu</strong>
											</div>
											<div class="pain-scale worst-pain" v-if="rowData.arankpknd_skala_nyeri_value >= 10">
												<img :src="assetLocal('global_assets/images/pain-scale/6-worst.png')" alt="Pain Scale"
													width="80" height="80">
												<strong>Tak Tertahankan</strong>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-12" v-else-if="isParent.row.ap_usia <= 6 && isParent.row.ap_usia >= 1">
							<div class="card">
								<div class="card-header bg-info">
									<h5 class="card-title font-weight-semibold">FLACC Scale</h5>
								</div>
								<div class="card-body">
									<div class="form-group">
										<label for="kidPainScale">Skala Nyeri</label>
										<table class="table table-bordered pain-scale-table">
											<thead>
												<tr>
													<th>Pengkajian</th>
													<th>Nilai 0</th>
													<th>Nilai 1</th>
													<th>Nilai 2</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(v,k) in (rowData.arankpknd_skala_nyeri_flacc||[])" :key="k">
													<th class="table-warning text-center"><img :src="assetLocal(v.img)"
															style="width:60px; height:60px;" alt="Pain Scale" width="80"
															height="80"><br>{{v.name||"-"}}</th>
													<td v-for="(vc,kc) in v.configVal" :key="kc">
														<div class="form-check form-check-inline align-middle">
															<label class="form-check-label" :for="v.name+k+kc">
																<b-form-radio type="radio" :value="vc.value"
																	v-model="rowData.arankpknd_skala_nyeri_flacc[k]['value']" :id="v.name+k+kc"
																	class="form-check-input-styled" />{{vc.text}}
															</label>
														</div>

													</td>
												</tr>
											</tbody>
											<tfoot>
												<tr class="table-info">
													<td colspan="4" class="text-info-700">
														<span>SKALA: {{hitungSkala}} </span>

														<span v-if="rowData.arankpknd_skala_nyeri_value == 0"
															class="border-left ml-2 pl-2 font-weight-semibold">Nyaman</span>
														<span
															v-else-if="rowData.arankpknd_skala_nyeri_value >= 1 && rowData.arankpknd_skala_nyeri_value < 4"
															class="border-left ml-2 pl-2 font-weight-semibold">Kurang Nyaman</span>
														<span
															v-else-if="rowData.arankpknd_skala_nyeri_value >= 4 && rowData.arankpknd_skala_nyeri_value < 7"
															class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
														<span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Berat</span>
													</td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div class="col-12" v-else>
							<div class="card">
								<div class="card-header bg-info">
									<h5 class="card-title font-weight-semibold">Neonatal-Infant Pain Scale (NIPS)</h5>
								</div>
								<div class="card-body">
									<div class="form-group">
										<label for="kidPainScale">Skala Nyeri</label>
										<table class="table table-bordered pain-scale-table">
											<thead>
												<tr>
													<th>Pengkajian</th>
													<th>Nilai 0</th>
													<th>Nilai 1</th>
													<th>Nilai 2</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(v,k) in (rowData.arankpknd_skala_nyeri_nips||[])" :key="k">
													<th class="table-warning text-center"><img :src="assetLocal(v.img)"
															style="width:60px; height:60px;" alt="Pain Scale" width="80"
															height="80"><br>{{v.name||"-"}}</th>
													<td v-for="(vc,kc) in v.configVal" :key="kc">
														<div class="form-check form-check-inline align-middle">
															<label class="form-check-label" :for="v.name+k+kc">
																<b-form-radio type="radio" :value="vc.value"
																	v-model="rowData.arankpknd_skala_nyeri_nips[k]['value']" :id="v.name+k+kc"
																	class="form-check-input-styled" />{{vc.text}}
															</label>
														</div>
														<br />
														<VValidate :name="v.name" v-model="rowData.arankpknd_skala_nyeri_nips[k]['value']"
															:rules="{required : 1}" />

													</td>
												</tr>
											</tbody>
											<tfoot>
												<tr class="table-info">
													<td colspan="4" class="text-info-700">
														<span>SKALA: {{hitungSkala}} </span>

														<span v-if="hitungSkala < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri
															Ringan, Tidak Nyeri</span>
														<span v-else-if="hitungSkala >= 3 && hitungSkala < 5"
															class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan
															(intervensi tanpa obat, dievaluasi selama 30 menit)</span>
														<span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi
															tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
													</td>
												</tr>
											</tfoot>
										</table>

										<span class="text-warning d-none" id="skala_nyeri_nips-typing">User Lain Sedang Mengisi ...</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row mt-2">
						<div class="col-12 mb-2">
							<div class="card mb-0">
								<div class="card-header bg_head_panel">
									<h6 class="card-title font-weight-semibold">Pemeriksaan Fisik (Termasuk pemeriksan Neurologis dan
										Muskoloskeletal)</h6>
								</div>
								<div class="card-body p-3">
									<div class="row gx-2">
										<div class="col-lg-4">
											<ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
												<li class="nav-item" @click="changeTabAnat(k)"
													v-for="(v,k) in rowData.arankpknd_pemeriksaan_fisik||[]" :key="k+'nav'">
													<a href="javascript:;" data-toggle="tab"
														:class="activeAnat == k ? 'nav-link active':'nav-link'">
														<div class="d-flex justify-content-between align-items-center">
															<span>{{v.name||"(Anatomi Baru)"}}</span>
															<div>
																<i class="icon-chevron-right"></i>
															</div>
														</div>
													</a>
												</li>
												<li class="nav-item border-top">
													<a href="javascript:;" @click="addAnatomi" class="nav-link border-top bg-light">
														<div class="d-flex justify-content-between align-items-center">
															<span><i class="icon-plus2 mr-2"></i>Tambah Anatomi</span>
														</div>
													</a>
												</li>
												<li class="nav-item border-top">
													<a href="javascript:;" @click="deleteAnatomi(activeAnat)"
														class="nav-link border-top bg-light">
														<div class="d-flex justify-content-between align-items-center">
															<span><i class="icon-trash mr-2"></i>Hapus Anatomi</span>
														</div>
													</a>
												</li>
											</ul>
										</div>
										<div class="col-lg-8">
											<div class="tab-content">
												<div v-for="(v,k) in rowData.arankpknd_pemeriksaan_fisik||[]" :key="k+'navAnat'"
													:class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
													<div class="form-group">
														<label for="">Jenis Anatomi<strong class="text-danger">*</strong></label>
														<div class="input-group flex-nowrap">
															<v-select class="flex-fill" placeholder="Pilih Pemeriksaan Fisik"
																@input="selectStatusLokalis($event,k)" v-model="v.type" :disabled="!v.isEdit"
																:options="getStatusLokalis(rowData.arankpknd_pemeriksaan_fisik,k)" label="text"
																:reduce="v=>v.value">
															</v-select>

															<div class="input-group-append">
																<button @click="editAnatomi(k)" type="button"
																	class="btn btn-info btn-labeled btn-labeled-left">
																	<b><i class="icon-pencil"></i></b>
																	Edit
																</button>
															</div>

															<div class="input-group-append">
																<button @click="saveAnatomi(k)" type="button"
																	class="btn btn-success btn-labeled btn-labeled-left">
																	<b><i class="icon-checkmark"></i></b>
																	Simpan
																</button>
															</div>
														</div>
													</div>

													<div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
														<div class="position-relative" :id="`anatomiNyeri${k}`" @click.stop.prevent>
															<div class="body_wrapper">
																<img :src="assetLocal(v.image)" class="img-fluid w-100" alt="">
															</div>
															<div class="anatomy-info" v-for="(v1,k1) in v.data||[]" :key="k1+'body'"
																:style="`top:${v1.y}%;left:${v1.x}%`">
																<a href="javascript:;" @click="openAnat(k1,k)" class="ai-point" v-b-tooltip.hover
																	:html="true" :title="v1.value"></a>
																<div class="anatomy-info-input" :class="{
																			'd-block': v1.isOpen,
																			'reversed': v1.isReversed
																			}" :style="v1.isReversed ? 'transform: translateX('+v1.reverseCount+')' : ''"
																	@click.stop.prevent>
																	<textarea v-model="v1.value" name="anatomyInput" id="anatomyInput" rows="2"
																		class="form-control form-control-sm"></textarea>
																	<small class="text-muted"><i class="icon-info22"></i> Keluhan
																		harus diisi</small>

																	<div class="mt-2 text-right">
																		<a href="javascript:;" class="btn btn-sm btn-danger"
																			@click.prevent.stop="removeAnat(k1,k)">Hapus</a>
																		<a href="javascript:;" @click.prevent.stop="closeAnat(k1,k)"
																			class="btn btn-sm btn-success">Simpan</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<span v-if="!(rowData.arankpknd_pemeriksaan_fisik||[]).length">Belum Ada Data Anatomi
													(Klik Tambah Untuk Menambahkan)</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row mt-2">
						<div class="col-md-3 mb-2">
							<div>
								<label for="painFrequency">Frekuensi Nyeri</label>
								<v-select placeholder="Pilih Salah Satu" v-model="rowData.arankpknd_frekuensi_nyeri"
									:options="isParent.Config.mr.ranapKNFrNyeri" label="text" :clearable="true" :reduce="v=>v.value">
								</v-select>
							</div>
						</div>
						<div class="col-md-3 mb-2">
							<div>
								<label for="painDuration">Lama Nyeri</label>
								<b-form-input v-model="rowData.arankpknd_lama_nyeri" :formatter="normalText" type="text"
									class="form-control" />
							</div>
						</div>
						<div class="col-md-6 mb-2">
							<div>
								<label for="painSpread">Menjalar?</label>
								<div class="form-row">
									<div class="col-md-4">
										<v-select placeholder="Pilih Salah Satu" v-model="rowData.arankpknd_menjalar"
											:options="isParent.Config.mr.ranapKNMenjalar" label="text" :clearable="true"
											:reduce="v=>v.value"></v-select>
									</div>
									<div class="col-md-8" v-if="rowData.arankpknd_menjalar == 'Y'">
										<b-form-input v-model="rowData.arankpknd_menjalar_text" :formatter="normalText" type="text"
											class="form-control" />
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div>
								<label for="painQuality">Kualitas Nyeri</label>
								<v-select placeholder="Pilih Salah Satu" v-model="rowData.arankpknd_kualitas_nyeri"
									:options="isParent.Config.mr.ranapKNKualitasNyeri" label="text" :clearable="true"
									:reduce="v=>v.value"></v-select>
							</div>
						</div>
						<div class="col-md-3">
							<div>
								<label for="painTrigger">Faktor pemicu/yang memperberat</label>
								<textarea v-model="rowData.arankpknd_faktor_pemicu" rows="2"
									class="form-control form-control-sm"></textarea>
							</div>
						</div>
						<div class="col-md-3">
							<div>
								<label for="painReduce">Faktor yang mengurangi</label>
								<textarea v-model="rowData.arankpknd_faktor_mengurangi" rows="2"
									class="form-control form-control-sm"></textarea>
							</div>
						</div>
						<div class="col-md-3">
							<div>
								<label for="painQuality">Tindak Lanjut Skala Nyeri</label>
								<v-select placeholder="Pilih Salah Satu" v-model="rowData.arankpknd_tindak_lanjut_skala_nyeri"
									:options="isParent.Config.mr.ranapKNTindakLanjut" label="text" :clearable="true"
									:reduce="v=>v.value"></v-select>
							</div>
						</div>
					</div>

					<div class="row mt-2" v-if="rowData.arankpkn_is_parent != 'Y'">

						<div class="col-12">
							<div class="card mb-0">
								<div class="card-header bg_head_panel">
									<h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
								</div>
								<div class="card-body p-3">
									<div class="row">

										<div class="col-md-4">
											<div class="form-group">
												<label>Tekanan Darah<small class="txt_mandatory">*</small></label>
												<div class="input-group">
													<b-form-input :formatter="number" v-model="rowData.arankpknd_ttv_tekanan_darah_min"
														type="text" name="name" class="form-control" placeholder="Systole" />

													<div class="input-group-append input-group-prepend"><span class="input-group-text">/</span>
													</div>

													<b-form-input :formatter="number" placeholder="Diastole"
														v-model="rowData.arankpknd_ttv_tekanan_darah_max" type="text" class="form-control" />
													<div class="input-group-append"><span class="input-group-text">mmHG</span></div>
												</div>
												<VValidate name="Tekanan Darah Min" v-model="rowData.arankpknd_ttv_tekanan_darah_min"
													:rules="toValidate(mrValidation.arankpknd_ttv_tekanan_darah_min)" />
												<VValidate name="Tekanan Darah Max" v-model="rowData.arankpknd_ttv_tekanan_darah_max"
													:rules="toValidate(mrValidation.arankpknd_ttv_tekanan_darah_max)" />
											</div>
										</div>

										<div class="col-md-4">
											<div class="form-group">
												<label>Nadi<small class="txt_mandatory">*</small></label>
												<div class="form-row">
													<div class="col-md-12">
														<div class="input-group">
															<b-form-input :formatter="number" v-model="rowData.arankpknd_ttv_nadi" type="text"
																class="form-control" />
															<div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
															<div class="input-group-append">
																<div style="width: 140px;">
																	<v-select placeholder="Pilih Label" v-model="rowData.arankpknd_ttv_label"
																		:options="Config.mr.StatusRegular" label="text" :clearable="true"
																		:reduce="v=>v.value"></v-select>
																</div>
															</div>
														</div>
													</div>
												</div>
												<VValidate name="Nadi" v-model="rowData.arankpknd_ttv_nadi"
													:rules="toValidate(mrValidation.arankpknd_ttv_nadi)" />
												<VValidate name="Label" v-model="rowData.arankpknd_ttv_label"
													:rules="toValidate(mrValidation.arankpknd_ttv_label)" />
											</div>
										</div>

										<div class="col-md-2">
											<div class="form-group">
												<label>Suhu<small class="txt_mandatory">*</small></label>
												<div class="form-row">
													<div class="col-md-12">
														<div class="input-group">
															<b-form-input :formatter="number" v-model="rowData.arankpknd_ttv_suhu" type="text"
																class="form-control" />
															<div class="input-group-append"><span class="input-group-text">C</span>
															</div>
														</div>
													</div>
												</div>
												<VValidate name="Suhu" v-model="rowData.arankpknd_ttv_suhu"
													:rules="toValidate(mrValidation.arankpknd_ttv_suhu)" />
											</div>
										</div>

										<div class="col-md-2">
											<div class="form-group">
												<label>Pernafasan<small class="txt_mandatory">*</small></label>
												<div class="input-group">
													<b-form-input :formatter="number" v-model="rowData.arankpknd_ttv_pernafasan" type="text"
														class="form-control" />
													<div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
												</div>
											</div>

											<VValidate name="Pernafasan" v-model="rowData.arankpknd_ttv_pernafasan"
												:rules="toValidate(mrValidation.arankpknd_ttv_pernafasan)" />
										</div>

										<div class="col-md-3">
											<div class="form-group">
												<label>Perawat yang Mengisi<small class="txt_mandatory">*</small></label>
												<b-form-input type="text" class="form-control" v-model="rowData.arankpknd_perawat" />

												<VValidate :name="'Perawat yang Mengisi'" v-model="rowData.arankpknd_perawat"
													:rules="toValidate(mrValidation.arankpknd_perawat)" class="d-block" />
											</div>
										</div>

										<div class="col-md-4">
											<div class="form-group">
												<label>Perawat yang memberikan Intervensi<small class="txt_mandatory">*</small></label>
												<b-form-input type="text" class="form-control"
													v-model="rowData.arankpknd_perawat_intervensi" />
											</div>

											<VValidate :name="'Perawat yang memberikan Intervensi'"
												v-model="rowData.arankpknd_perawat_intervensi"
												:rules="toValidate(mrValidation.arankpknd_perawat_intervensi)" class="d-block" />
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-12">
							<div class="card mb-0">
								<div class="card-header bg_head_panel">
									<h6 class="card-title font-weight-semibold">Intervensi Farmakologi</h6>
								</div>
								<div class="card-body p-3">
									<ResepObat />
								</div>
							</div>
						</div>

						<div class="col-6">
							<div class="card mb-0">
								<div class="card-header bg_head_panel">
									<h6 class="card-title font-weight-semibold">Intervensi Non Farmakologi</h6>
								</div>
								<div class="card-body p-3">
									<div class="row">
										<div class="col-md-12">
											<v-select placeholder="-- Pilih Intervensi --"
												v-model="rowData.arankpknd_intervensi_non_farmakologi"
												:options="Config.mr.mIntervensiNonFarmakologi" label="text" :clearable="true"
												:reduce="v=>v.value"></v-select>

											<VValidate name="Intervensi Non Farmakologi"
												v-model="rowData.arankpknd_intervensi_non_farmakologi"
												:rules="toValidate(mrValidation.arankpknd_intervensi_non_farmakologi)" />
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>


					<div class="card mb-0 mt-3">
						<div class="card-header bg_head_panel">
							<h6 class="card-title font-weight-semibold">Tindakan Lanjutan</h6>
						</div>
						<div class="card-body">
							<div class="row mt-2">
								<div class="col-md-5">
									<label for="">Jadwal Monitoring <span class="text-danger">*</span></label>
									<b-form-radio-group :options="Config.mr.lanjutanMonitoring"
										v-model="rowData.arankpkn_is_next_monitoring" />
									<VValidate :name="'Jadwal Monitoring'" v-model="rowData.arankpkn_is_next_monitoring"
										:rules="{required: 1}" />
								</div>

								<div class="col-md-5" v-if="rowData.arankpkn_is_next_monitoring == 'Y'">
									<label>Interval Waktu<small class="txt_mandatory">*</small></label>
									<div class="form-row">
										<div class="col-lg-5">
											<v-select placeholder="-- Pilih Salah Satu -- " v-model="rowData.arankpknd_label"
												:options="isParent.Config.mr.waktuKajianInterval" label="text" :clearable="true"
												:reduce="v=>v.value"></v-select>

											<VValidate :name="'Interval Waktu'" v-model="rowData.arankpknd_label"
												:rules="toValidate(mrValidation.arankpknd_label)" class="d-block" />
										</div>
										<div class="col-lg-7" v-if="rowData.arankpknd_label == 999999">
											<div class="form-group">
												<div class="input-group">
													<b-form-input :formatter="numberOnly" v-model="rowData.arankpknd_waktu" type="text"
														class="form-control" />
													<div class="input-group-append">
														<div style="width: 120px;">
															<v-select placeholder="-- Pilih -- " v-model="rowData.arankpknd_waktu_label"
																:options="isParent.Config.mr.ketWaktuInterval" label="text" :clearable="true"
																:reduce="v=>v.value"></v-select>
														</div>
													</div>

													<VValidate :name="'Interval Waktu Lainnya'" v-model="rowData.arankpknd_waktu"
														:rules="toValidate(mrValidation.arankpknd_waktu)" class="d-block" />

													<VValidate :name="'Keterangan Waktu'" v-model="rowData.arankpknd_waktu_label"
														:rules="toValidate(mrValidation.arankpknd_waktu_label)" class="d-block" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="card-footer">
						<div class="text-right">
							<button type="button" @click="back()" class="btn btn-light mr-2">Back</button>
							<button type="button" class="btn btn-info mr-2" @click="doSave()">Simpan Sementara <i
									class="icon-paperplane mr-2"></i></button>
							<button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane mr-2"></i></button>
						</div>
					</div>

				</div>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import ResepObat from './ResepObat.vue'

export default{
  extends: GlobalVue,
  data() {
    return {
        rowReg: {},
        activeAnat: 0,
        rowData: {},
        isMonitoring: false,
        
        resepObat: [],
        mRacikan: [],
        riwayatObat: [],
        mObatPreferensi: [],
        mDosis: [],
        mAlatKesehatan: [],
        mAlatInfus: [],
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
     apiGetForm(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.$route.params.kajianNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.kajianNo : this.modulePage+'/'+this.$route.params.pageSlug
      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.params.kajianNo){
        Gen.apiRest(
          "/get/"+url+'?master=1', 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
        })
      }    
    },
    toValidate(val){
      return {...val}
    },        
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'
      data.ap_usia = this.isParent.row.ap_usia

      this.rowData.arankpknd_skala_nyeri_value = this.hitungSkalaFunc()
      this.rowData.arankpkn_skor = this.hitungSkalaFunc()
      
      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),

    doSave(){
      let data = this.rowData
      data.arankpkn_updated_by = this.user.id
      data.arankpkn_updated_date = moment().format("YYYY-MM-DD HH:mm:ss")
      data.type = 'auto-save'
      data.ap_usia = this.isParent.row.ap_usia

      this.loadingOverlay = true

      Gen.apiRest(
      "/do/" + this.modulePage, {
        data: data
      },
      "POST"
      ).then(res => {
        this.loadingOverlay = false
        
        let resp = res.data
        resp.statusType = 200
        this.$swal({
            title: "Data Berhasil Disimpan",
            icon: "success",
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(result => {
            this.$router.push({ name: this.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
        })
      })
    },

    editAnatomi(k) {
      this.rowData.arankpknd_pemeriksaan_fisik[k]['isEdit'] = true
    },
    saveAnatomi(k) {
      this.rowData.arankpknd_pemeriksaan_fisik[k]['isEdit'] = false
    },
    changeTabAnat(k, isAdd = false) {
        this.activeAnat = k
        setTimeout(() => {
        if (isAdd) {
            this.setPosisionNyeri()
        }
        }, 100)
    },
    deleteAnatomi(key) {
        this.rowData.arankpknd_pemeriksaan_fisik.splice(key, 1)
        this.activeAnat = (this.rowData.arankpknd_pemeriksaan_fisik || []).length - 1
    },
    addAnatomi() {
        this.rowData.arankpknd_pemeriksaan_fisik = this.rowData.arankpknd_pemeriksaan_fisik ? this.rowData.arankpknd_pemeriksaan_fisik : []
        this.rowData.arankpknd_pemeriksaan_fisik.push({
            name: '',
            type: null,
            image: '',
            isEdit: true,
            data: [],
        })
        this.activeAnat = (this.rowData.arankpknd_pemeriksaan_fisik || []).length - 1
    },
    selectStatusLokalis(e, k) {
        let idx = this.isParent.Config.mr.configStatusLokalis.findIndex(x => x.value == e)
        if (idx !== -1) {
            let data = this.isParent.Config.mr.configStatusLokalis[idx]
            this.rowData.arankpknd_pemeriksaan_fisik[k]['name'] = data.text
            if (this.rowData.ap_gender == 2) {
                this.rowData.arankpknd_pemeriksaan_fisik[k]['image'] = data.image2
            } else {
                this.rowData.arankpknd_pemeriksaan_fisik[k]['image'] = data.image
            }
        }

        this.rowData.arankpknd_pemeriksaan_fisik[this.activeAnat]['data'] = []

        if (!$("#anatomiNyeri" + this.activeAnat).length) {
            this.changeTabAnat(k, true)
        } else {
            this.changeTabAnat(k)
        }
    },

    getStatusLokalis(data, k) {
        const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
        return k2 != k
        }), v2 => {
        return v2.type
        })
        return _.filter(this.isParent.Config.mr.configStatusLokalis, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
        })
    },

    removeAnat(k, kp) {
        this.rowData.arankpknd_pemeriksaan_fisik[kp]['data'].splice(k, 1)
    },
    closeAnat(k, kp) {
        if (this.rowData.arankpknd_pemeriksaan_fisik[kp]['data'][k]['value']) {
        this.rowData.arankpknd_pemeriksaan_fisik[kp]['data'][k]['isOpen'] = false
        this.rowData.arankpknd_pemeriksaan_fisik[kp]['data'][k]['y'] += (62 / $('#anatomiNyeri' + this.activeAnat).height()) * 100
        }
    },
    openAnat(k, kp) {
        this.rowData.arankpknd_pemeriksaan_fisik[kp]['data'][k]['y'] -= (62 / $('#anatomiNyeri' + this.activeAnat).height()) * 100
        this.rowData.arankpknd_pemeriksaan_fisik[kp]['data'][k]['isOpen'] = true
    },
    setPosisionNyeri() {
        let posX = false
        let posY = false
        let self = this

        $("body").mousemove(function (e) {
        if (!$("body").hasClass("active")) $("body").addClass("active")
        if ($("#anatomiNyeri" + self.activeAnat).length) {
            posX = parseInt(e.pageX - ($("#anatomiNyeri" + self.activeAnat).offset().left + 8))
            posY = parseInt(e.pageY - ($("#anatomiNyeri" + self.activeAnat).offset().top + 52))
        }
        })


        $('#anatomiNyeri' + self.activeAnat).on('click', function (e) {
        e.stopPropagation()
        let index = self.rowData.arankpknd_pemeriksaan_fisik[self.activeAnat]['data'].findIndex(x => x.isOpen == true)

        if (self.rowData.arankpknd_pemeriksaan_fisik[self.activeAnat]['isEdit']) {
            if (index !== -1) {
                  self.rowData.arankpknd_pemeriksaan_fisik[self.activeAnat]['data'].splice(index, 1)
            } else {
                self.rowData.arankpknd_pemeriksaan_fisik[self.activeAnat]['data'].push({
                x: (posX / $(this).width()) * 100,
                y: (posY / $(this).height()) * 100,
                value: '',
                isOpen: true,
                isReversed: posX > 300 ? true : false,
                reverseCount: `-111%`
                // reverseCount: `-${((posX - 300) / 2) - 16 + 300}px`
            })
            }
        }
        })
    },
    
    
    autoSaveResepObat: _.debounce(function (data) {
        let dataPost = {
            type: 'auto-save-resep-obat',
            data: data
        }
        Gen.apiRest(
            "/do/" + this.modulePage, {
            data: dataPost
            },
            "POST"
        )
    }, 1000),

    hitungSkalaFunc(){
      let value = 0
      if(this.rowData.ap_usia <= 6 && this.rowData.ap_usia >= 1){
        for(let i = 0; i < (this.rowData.arankpknd_skala_nyeri_flacc||[]).length; i++){
          value += this.rowData.arankpknd_skala_nyeri_flacc[i]['value']
        }   
      }else if(this.rowData.ap_usia < 1){
        for(let i = 0; i < (this.rowData.arankpknd_skala_nyeri_nips||[]).length; i++){
          value += this.rowData.arankpknd_skala_nyeri_nips[i]['value']
        }   
      }else{
        value += this.rowData.arankpknd_skala_nyeri_value
      }
      return value
    },  

    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            text: "Pastikan Jadwal Monitoring yang diiisi telah sesuai",
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowData
              data.type = 'submit-data'
              data.resepObat = this.resepObat
              data.ap_usia = this.isParent.row.ap_usia

              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      this.$router.push({ name: this.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
                  }).catch(err => {
                    this.loadingOverlay = false
                    if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                  })
              })
            }
          })
        }
      })
    }
  },
  components: {
    Datepicker,VueTimepicker,ResepObat
  },
  computed: {
    isParent(){
      return this.$parent
    },
    hitungSkala(){
      let value = 0
      if(this.rowData.ap_usia <= 6 && this.rowData.ap_usia >= 1){
        for(let i = 0; i < (this.rowData.arankpknd_skala_nyeri_flacc||[]).length; i++){
          value += this.rowData.arankpknd_skala_nyeri_flacc[i]['value']
        }   
      }else if(this.rowData.ap_usia < 1){
        for(let i = 0; i < (this.rowData.arankpknd_skala_nyeri_nips||[]).length; i++){
          value += this.rowData.arankpknd_skala_nyeri_nips[i]['value']
        }   
      }else{
        value += this.rowData.arankpknd_skala_nyeri_value
      }
      return value
    },  
  },
  mounted() {
    this.apiGetForm()
    setTimeout(() => {
        this.setPosisionNyeri()
        this.rowData.arankpknd_perawat = this.user.fullName
        this.rowData.arankpknd_perawat_intervensi = this.user.fullName

        let value = 0
        if(this.rowData.ap_usia <= 6 && this.rowData.ap_usia >= 1){
            for(let i = 0; i < (this.rowData.arankpknd_skala_nyeri_flacc||[]).length; i++){
            value += this.rowData.arankpknd_skala_nyeri_flacc[i]['value']
            }   
        }else if(this.rowData.ap_usia < 1){
            for(let i = 0; i < (this.rowData.arankpknd_skala_nyeri_nips||[]).length; i++){
            value += this.rowData.arankpknd_skala_nyeri_nips[i]['value']
            }   
        }else{
            value += this.rowData.arankpknd_skala_nyeri_value
        }
        console.log(value)
        console.log(this.hitungSkala)

    },1500)
  },
  watch: {
    rowData: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    hitungSkala(v){
        setTimeout(()=>{
            this.rowData.arankpknd_skala_nyeri_value = v
            this.rowData.arankpkn_skor = v
        },250)
    },
    
    resepObat: {
      handler(v) {
        this.autoSaveResepObat(v)
      },
      deep: true
    },
  }
}

</script>