<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-12 mb-2">
        <div class="bg_card_blue p-3">
          <table class="table table-borderless table-sm text-uppercase">
            <tbody>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Reg</h4>
                    <p>{{isParent.rowReg.aranr_reg_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Rekam Medis</h4>
                    <p>{{isParent.rowReg.ap_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Pasien</h4>
                    <p>{{isParent.rowReg.ap_fullname||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Tempat, Tanggal Lahir </h4>
                    <p>{{isParent.rowReg.ap_address||"-"}}, 
                    <span v-if="isParent.rowReg.ap_dob">{{isParent.rowReg.ap_dob | moment("DD MMMM YYYY")}}</span>
                    <span v-else> - </span>
                    </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Usia</h4>
                    <p>{{isParent.rowReg.ap_usia_with_ket}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{isParent.rowReg.ap_gender == 1 ? 'Laki-Laki' : 'Perempuan'}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <div>
      <div class="card" v-if="isParent.row.ap_usia > 6">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Numeric Pain Rating Scale</h6>
        </div>
        <div class="card-body p-3">
          <div class="form-group">
                <label for="">Skala Nyeri</label>
                <div class="pain-scale no-pain" v-if="rowData.arankpknd_skala_nyeri_value == 0">
                    <img :src="isParent.assetLocal('global_assets/images/pain-scale/1-no-pain.png')" alt="Pain Scale" width="80" height="80">
                    <strong>Tidak Ada Nyeri</strong>
                </div>
                <div class="pain-scale mild-pain" v-if="rowData.arankpknd_skala_nyeri_value >= 1 && rowData.arankpknd_skala_nyeri_value <= 3">
                    <img :src="isParent.assetLocal('global_assets/images/pain-scale/2-mild-pain.png')" alt="Pain Scale" width="80" height="80">
                    <strong>Sedikit Nyeri</strong>
                </div>
                <div class="pain-scale moderate-pain" v-if="rowData.arankpknd_skala_nyeri_value >= 4 && rowData.arankpknd_skala_nyeri_value <= 5">
                    <img :src="isParent.assetLocal('global_assets/images/pain-scale/3-moderate-pain.png')" alt="Pain Scale" width="80" height="80">
                    <strong>Agak Mengganggu</strong>
                </div>
                <div class="pain-scale severe-pain" v-if="rowData.arankpknd_skala_nyeri_value >= 6 && rowData.arankpknd_skala_nyeri_value <= 7">
                    <img :src="isParent.assetLocal('global_assets/images/pain-scale/4-severe-pain.png')" alt="Pain Scale" width="80" height="80">
                    <strong>Mengganggu Aktivitas</strong>
                </div>
                <div class="pain-scale worst-pain" v-if="rowData.arankpknd_skala_nyeri_value >= 8 && rowData.arankpknd_skala_nyeri_value <= 9">
                    <img :src="isParent.assetLocal('global_assets/images/pain-scale/5-very-severe.png')" alt="Pain Scale" width="80" height="80">
                    <strong>Sangat Mengganggu</strong>
                </div>
                <div class="pain-scale worst-pain" v-if="rowData.arankpknd_skala_nyeri_value >= 10">
                    <img :src="isParent.assetLocal('global_assets/images/pain-scale/6-worst.png')" alt="Pain Scale" width="80" height="80">
                    <strong>Tak Tertahankan</strong>
                </div>
          </div>
        </div>
      </div>
      
      <div class="card" v-else-if="(isParent.row.ap_usia <= 6 && isParent.row.ap_usia >= 1)">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">FLACC Scale</h6>
        </div>
        <div class="card-body p-3">
          <div class="form-group">
            <label for="kidPainScale">Skala Nyeri</label>
            <table class="table table-bordered pain-scale-table">
              <thead>
                <tr>
                  <th>Pengkajian</th>
                  <th>Hasil</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in rowData.arankpknd_skala_nyeri" :key="k+'akn'">
                    <th class="table-warning text-center" style="width: 30%;"><img :src="isParent.assetLocal(v.img)"
                        style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>
                        {{v.name}}</th>
                    <td class="p-2">
                        {{getConfigDynamic(v.configVal,v.value)||"-"}}
                    </td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="table-info">
                  <td colspan="4" class="text-info-700">
                    <span>NILAI SKALA NYERI: {{rowData.arankpknd_skala_nyeri_value||"-"}}</span>
                    <span v-if="rowData.arankpknd_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Nyaman</span>
                    <span v-else-if="rowData.arankpknd_skala_nyeri_value >= 1 && rowData.arankpknd_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Kurang Nyaman</span>
                    <span v-else-if="rowData.arankpknd_skala_nyeri_value >= 4 && rowData.arankpknd_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                    <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Berat</span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div class="card" v-else-if="(isParent.row.ap_usia < 1)">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Neonatal-Infant Pain Scale (NIPS)</h6>
        </div>
        <div class="card-body p-3">
          <div class="form-group">
            <label for="kidPainScale">Skala Nyeri</label>
            <table class="table table-bordered pain-scale-table">
              <thead>
                <tr>
                  <th>Pengkajian</th>
                  <th>Hasil</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in rowData.arankpknd_skala_nyeri_nips" :key="k+'akn'">
                    <th class="table-warning text-center" style="width: 30%;"><img :src="isParent.assetLocal(v.img)"
                        style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>
                        {{v.name}}</th>
                    <td class="p-2">
                        {{getConfigDynamic(v.configVal,v.value)||"-"}}
                    </td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="table-info">
                  <td colspan="4" class="text-info-700">
                    <span>NILAI SKALA NYERI: {{rowData.arankpknd_skala_nyeri_value||0}}</span>
                    
                    <span v-if="rowData.arankpknd_skala_nyeri_value < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                    <span v-else-if="rowData.arankpknd_skala_nyeri_value >= 3 && rowData.arankpknd_skala_nyeri_value < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                    <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div class="card mb-0">
        <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik (Termasuk pemeriksan Neurologis dan Muskoloskeletal)</h6>
        </div>
        
        <div class="card-body p-3">
            <div class="row gx-2">
                <div class="col-lg-4">
                <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
                <li class="nav-item" @click="changeTabAnat(k)" v-for="(v,k) in rowData.arankpknd_pemeriksaan_fisik||[]" :key="k+'nav'">
                    <a href="javascript:;" data-toggle="tab" 
                    :class="activeAnat == k ? 'nav-link active':'nav-link'">
                    <div class="d-flex justify-content-between align-items-center">
                        <span>{{v.name||"(Anatomi Baru)"}}</span>
                        <div>
                        <i class="icon-chevron-right"></i>
                        </div>
                    </div>
                    </a>
                </li>
                </ul>
                </div>
                <div class="col-lg-8">
                <div class="tab-content">
                    <div v-for="(v,k) in rowData.arankpknd_pemeriksaan_fisik||[]" :key="k+'navAnat'" :class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
                    <div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
                        <div class="position-relative" :id="`anatomiNyeri${k}`" @click.stop.prevent>
                        <div class="body_wrapper">
                            <img :src="isParent.assetLocal(v.image)" class="img-fluid w-100" alt="">
                        </div>
                        <div class="anatomy-info" v-for="(v1,k1) in v.data||[]" :key="k1+'body'"
                            :style="`top:${v1.y}%;left:${v1.x}%`">
                            <a href="javascript:;" class="ai-point" v-b-tooltip.hover :html="true"
                            :title="v1.value"></a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>                  
      </div>

      <div class="card">
        <div class="card-body">
          <table class="table table-bordered">
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Lokasi Nyeri</h4>
                  <p>{{rowData.arankpknd_frekuensi_nyeri||"-"}}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Lama Nyeri</h4>
                  <p>{{rowData.arankpknd_lama_nyeri||"-"}}</p>                        
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Durasi Nyeri</h4>
                  <p>{{rowData.arankpknd_durasi_nyeri||"-"}}</p>                        
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Menjalar?</h4>
                  <p>{{getConfigDynamic(Config.mr.ranapKNMenjalar,rowData.arankpknd_menjalar)||"-"}}</p>      
                  <p v-if="rowData.arankpknd_menjalar != 'N'">{{rowData.arankpknd_menjalar_text||"-"}}</p>                  
                </div>
              </td>
            </tr>
            
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Kualitas Nyeri</h4>
                  <p>{{rowData.arankpknd_kualitas_nyeri||"-"}}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Faktor pemicu/yang memperberat</h4>
                  <p>{{rowData.arankpknd_faktor_pemicu||"-"}}</p>                        
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Faktor yang mengurangi</h4>
                  <p>{{rowData.arankpknd_faktor_mengurangi||"-"}}</p>                        
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Tindak Lanjut Skala Nyeri</h4>
                  <p>{{rowData.arankpknd_tindak_lanjut_skala_nyeri||"-"}}</p>                        
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="row">
      
      
        <div class="col-md-3">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Perawat yang Menguji</h6>
            </div>
            <div class="card-body p-3">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Nama Perawat</h4>
                        <p>{{rowData.arankpknd_perawat||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Perawat intervensi</h6>
            </div>
            <div class="card-body p-3">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Nama Perawat</h4>
                        <p>{{rowData.arankpknd_perawat_intervensi||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        

        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
            </div>
            <div class="card-body p-3">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Tensi Darah</h4>
                        <p>{{rowData.arankpknd_ttv_tekanan_darah_min||"-"}}/{{rowData.arankpknd_ttv_tekanan_darah_max||"-"}} mmHG</p>
                      </div>
                    </td>

                    <td>
                      <div class="result_tab">
                        <h4>Nadi </h4>
                        <p>{{rowData.arankpknd_ttv_nadi||"-"}} x/mnt, {{getConfigDynamic(Config.mr.StatusRegular,rowData.arankpknd_ttv_label)||"-"}}</p>
                      </div>
                    </td>

                    <td>
                      <div class="result_tab">
                        <h4>Pernafasan</h4>
                        <p>{{rowData.arankpknd_ttv_pernafasan||"-"}} x/mnt</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Suhu</h4>
                        <p>{{rowData.arankpknd_ttv_suhu||"-"}} C</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        <div class="col-md-8">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Intervensi Non Farmakologi</h6>
            </div>
            <div class="card-body p-3">
              <table class="table-bordered table table-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Jenis</th>
                    <th>Nama Obat</th>
                    <th>Frekuensi</th>
                    <th>Rute</th>
                    <th>Keterangan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v,k) in resepObat||[]" :key="k">
                    <td>{{k+1}}</td>
                    <td>{{getConfigDynamic(Config.mr.configResepRanap,v.arankpknr_jenis)||"-"}}</td>
                    <td>{{v.arankpknr_nama||"-"}}</td>
                    <td>{{v.mdo_name||"-"}}</td>
                    <td>{{v.arankpknr_rute||"-"}}</td>
                    <td>{{v.arankpknr_keterangan||"-"}}</td>
                  </tr>
                  <tr v-if="!(resepObat||[]).length">
                    <td colspan="6" class="text-center">Tidak ada Data</td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>
        </div>

        
        <div class="col-md-3">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Interval Waktu</h6>
            </div>
            <div class="card-body p-3">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Interval Waktu</h4>
                        <p v-if="rowData.arankpknd_label != 999999">{{getConfigDynamic(Config.mr.waktuKajianInterval,rowData.arankpknd_label)||"-"}}</p>
                        <p v-else>{{rowData.arankpknd_waktu}} {{getConfigDynamic(Config.mr.ketWaktuInterval,rowData.arankpknd_waktu_label)||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue,
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
  },
  data() {
    return {
      rowData: {},
      rowReg: {},
      resepObat: [],

      activeAnat: 0,
    }
  },
  methods: {
    changeTabAnat(k, isAdd = false) {
      this.activeAnat = k
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    apiGetForm(params = {}, page = 1){
      if(!this.isParent.regId) if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = (this.isParent.regId ? "RanapPasienKajianNyeri" : this.modulePage)+'/'+(this.$parent.regId||this.$route.params.pageSlug)+'/'+this.isParent.rowEdit.arankpkn_id
      
      Gen.apiRest(
        "/get/"+url, 
        {
        params: Object.assign({page: page}, paramsQuery, params.query||{})
        }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })
    },
  },
  mounted() {
    this.apiGetForm()
  }
}
</script>